// This is a port of Google Android `libphonenumber`'s
// `phonenumberutil.js` of December 31th, 2018.
//
// https://github.com/googlei18n/libphonenumber/commits/master/javascript/i18n/phonenumbers/phonenumberutil.js

import {
	VALID_DIGITS,
	VALID_PUNCTUATION,
	PLUS_CHARS,
	MIN_LENGTH_FOR_NSN,
	MAX_LENGTH_FOR_NSN,
	MAX_LENGTH_COUNTRY_CODE
} from './constants'

import { matchesEntirely } from './util'
import ParseError from './ParseError'
import Metadata from './metadata'
import isViablePhoneNumber from './isViablePhoneNumber'
import { extractExtension } from './extension'
import parseIncompletePhoneNumber from './parseIncompletePhoneNumber'
import getCountryCallingCode from './getCountryCallingCode'
import getNumberType, { checkNumberLengthForType } from './getNumberType_'
import { isPossibleNumber } from './isPossibleNumber_'
import { stripIDDPrefix } from './IDD'
import { parseRFC3966 } from './RFC3966'
import PhoneNumber from './PhoneNumber'

// We don't allow input strings for parsing to be longer than 250 chars.
// This prevents malicious input from consuming CPU.
const MAX_INPUT_STRING_LENGTH = 250

// This consists of the plus symbol, digits, and arabic-indic digits.
const PHONE_NUMBER_START_PATTERN = new RegExp('[' + PLUS_CHARS + VALID_DIGITS + ']')

// Regular expression of trailing characters that we want to remove.
const AFTER_PHONE_NUMBER_END_PATTERN = new RegExp('[^' + VALID_DIGITS + ']+$')

// `options`:
//  {
//    country:
//    {
//      restrict - (a two-letter country code)
//                 the phone number must be in this country
//
//      default - (a two-letter country code)
//                default country to use for phone number parsing and validation
//                (if no country code could be derived from the phone number)
//    }
//  }
//
// Returns `{ country, number }`
//
// Example use cases:
//
// ```js
// parse('8 (800) 555-35-35', 'RU')
// parse('8 (800) 555-35-35', 'RU', metadata)
// parse('8 (800) 555-35-35', { country: { default: 'RU' } })
// parse('8 (800) 555-35-35', { country: { default: 'RU' } }, metadata)
// parse('+7 800 555 35 35')
// parse('+7 800 555 35 35', metadata)
// ```
//
export default function parse(text, options, metadata) {
	// If assigning the `{}` default value is moved to the arguments above,
	// code coverage would decrease for some weird reason.
	options = options || {}

	metadata = new Metadata(metadata)

	// Validate `defaultCountry`.
	if (options.defaultCountry && !metadata.hasCountry(options.defaultCountry)) {
		if (options.v2) {
			throw new ParseError('INVALID_COUNTRY')
		}
		throw new Error(`Unknown country: ${options.defaultCountry}`)
	}

	// Parse the phone number.
	const { number: formattedPhoneNumber, ext } = parseInput(text, options.v2)

	// If the phone number is not viable then return nothing.
	if (!formattedPhoneNumber) {
		if (options.v2) {
			throw new ParseError('NOT_A_NUMBER')
		}
		return {}
	}

	const {
		country,
		nationalNumber,
		countryCallingCode,
		carrierCode
	} = parsePhoneNumber(
		formattedPhoneNumber,
		options.defaultCountry,
		options.defaultCallingCode,
		metadata
	)

	if (!metadata.hasSelectedNumberingPlan()) {
		if (options.v2) {
			throw new ParseError('INVALID_COUNTRY')
		}
		return {}
	}

	// Validate national (significant) number length.
	if (!nationalNumber || nationalNumber.length < MIN_LENGTH_FOR_NSN) {
		// Won't throw here because the regexp already demands length > 1.
		/* istanbul ignore if */
		if (options.v2) {
			throw new ParseError('TOO_SHORT')
		}
		// Google's demo just throws an error in this case.
		return {}
	}

	// Validate national (significant) number length.
	//
	// A sidenote:
	//
	// They say that sometimes national (significant) numbers
	// can be longer than `MAX_LENGTH_FOR_NSN` (e.g. in Germany).
	// https://github.com/googlei18n/libphonenumber/blob/7e1748645552da39c4e1ba731e47969d97bdb539/resources/phonenumber.proto#L36
	// Such numbers will just be discarded.
	//
	if (nationalNumber.length > MAX_LENGTH_FOR_NSN) {
		if (options.v2) {
			throw new ParseError('TOO_LONG')
		}
		// Google's demo just throws an error in this case.
		return {}
	}

	if (options.v2) {
		const phoneNumber = new PhoneNumber(
			countryCallingCode,
			nationalNumber,
			metadata.metadata
		)
		if (country) {
			phoneNumber.country = country
		}
		if (carrierCode) {
			phoneNumber.carrierCode = carrierCode
		}
		if (ext) {
			phoneNumber.ext = ext
		}
		return phoneNumber
	}

	// Check if national phone number pattern matches the number.
	// National number pattern is different for each country,
	// even for those ones which are part of the "NANPA" group.
	const valid = country && matchesEntirely(nationalNumber, metadata.nationalNumberPattern()) ? true : false

	if (!options.extended) {
		return valid ? result(country, nationalNumber, ext) : {}
	}

	return {
		country,
		countryCallingCode,
		carrierCode,
		valid,
		possible: valid ? true : (options.extended === true) && metadata.possibleLengths() && isPossibleNumber(nationalNumber, countryCallingCode !== undefined, metadata),
		phone: nationalNumber,
		ext
	}
}

/**
 * Extracts a formatted phone number from text.
 * Doesn't guarantee that the extracted phone number
 * is a valid phone number (for example, doesn't validate its length).
 * @param  {string} text
 * @return {string}
 * @example
 * // Returns "(213) 373-4253".
 * extractFormattedPhoneNumber("Call (213) 373-4253 for assistance.")
 */
export function extractFormattedPhoneNumber(text, v2) {
	if (!text) {
		return
	}
	if (text.length > MAX_INPUT_STRING_LENGTH) {
		if (v2) {
			throw new ParseError('TOO_LONG')
		}
		return
	}
	// Attempt to extract a possible number from the string passed in
	const startsAt = text.search(PHONE_NUMBER_START_PATTERN)
	if (startsAt < 0) {
		return
	}
	return text
		// Trim everything to the left of the phone number
		.slice(startsAt)
		// Remove trailing non-numerical characters
		.replace(AFTER_PHONE_NUMBER_END_PATTERN, '')
}

/**
 * Strips any national prefix (such as 0, 1) present in the number provided.
 * "Carrier codes" are only used  in Colombia and Brazil,
 * and only when dialing within those countries from a mobile phone to a fixed line number.
 * Sometimes it won't actually strip national prefix
 * and will instead prepend some digits to the `number`:
 * for example, when number `2345678` is passed with `VI` country selected,
 * it will return `{ number: "3402345678" }`, because `340` area code is prepended.
 * @param {string} number — National number digits.
 * @param {object} metadata — Metadata with country selected.
 * @return {object} `{ number, carrierCode }`.
 */
export function stripNationalPrefixAndCarrierCode(number, metadata) {
	if (!number) {
		return { number }
	}

	if (!metadata.nationalPrefixForParsing()) {
		return { number }
	}

	// See METADATA.md for the description of
	// `national_prefix_for_parsing` and `national_prefix_transform_rule`.

	// Attempt to parse the first digits as a national prefix.
	const prefixPattern = new RegExp('^(?:' + metadata.nationalPrefixForParsing() + ')')
	const prefixMatch = prefixPattern.exec(number)

	if (!prefixMatch) {
		return { number }
	}

	let nationalSignificantNumber
	let carrierCode

	// If a "capturing group" didn't match
	// then its element in `prefixMatch[]` array will be `undefined`.

	const capturedGroupsCount = prefixMatch.length - 1
	if (metadata.nationalPrefixTransformRule() &&
		capturedGroupsCount > 0 && prefixMatch[capturedGroupsCount]) {
		nationalSignificantNumber = number.replace(
			prefixPattern,
			metadata.nationalPrefixTransformRule()
		)
		// Carrier code is the last captured group,
		// but only when there's more than one captured group.
		if (capturedGroupsCount > 1 && prefixMatch[capturedGroupsCount]) {
			carrierCode = prefixMatch[1]
		}
	}
	// If it's a simple-enough case then just
	// strip the national prefix from the number.
	else {
		// National prefix is the whole substring matched by
		// the `national_prefix_for_parsing` regexp.
		const nationalPrefix = prefixMatch[0]
		nationalSignificantNumber = number.slice(nationalPrefix.length)
		// Carrier code is the last captured group.
		if (capturedGroupsCount > 0) {
			carrierCode = prefixMatch[1]
		}
	}

	// The following is done in `get_country_and_national_number_for_local_number()` instead.
	//
	// // Verify the parsed national (significant) number for this country
	// const national_number_rule = new RegExp(metadata.nationalNumberPattern())
	// //
	// // If the original number (before stripping national prefix) was viable,
	// // and the resultant number is not, then prefer the original phone number.
	// // This is because for some countries (e.g. Russia) the same digit could be both
	// // a national prefix and a leading digit of a valid national phone number,
	// // like `8` is the national prefix for Russia and both
	// // `8 800 555 35 35` and `800 555 35 35` are valid numbers.
	// if (matchesEntirely(number, national_number_rule) &&
	// 	!matchesEntirely(nationalSignificantNumber, national_number_rule)) {
	// 	return number
	// }

	// Return the parsed national (significant) number
   return {
   	number: nationalSignificantNumber,
   	carrierCode
   }
}

export function findCountryCode(callingCode, nationalPhoneNumber, metadata) {
	if (metadata.isNonGeographicCallingCode(callingCode)) {
		return '001'
	}
	// Is always non-empty, because `callingCode` is always valid
	const possibleCountries = metadata.getCountryCodesForCallingCode(callingCode)
	if (!possibleCountries) {
		return
	}
	// If there's just one country corresponding to the country code,
	// then just return it, without further phone number digits validation.
	if (possibleCountries.length === 1) {
		return possibleCountries[0]
	}
	return _findCountryCode(possibleCountries, nationalPhoneNumber, metadata.metadata)
}

// Changes `metadata` `country`.
function _findCountryCode(possibleCountries, nationalPhoneNumber, metadata) {
	metadata = new Metadata(metadata)
	for (const country of possibleCountries) {
		metadata.country(country)
		// Leading digits check would be the simplest one
		if (metadata.leadingDigits()) {
			if (nationalPhoneNumber &&
				nationalPhoneNumber.search(metadata.leadingDigits()) === 0) {
				return country
			}
		}
		// Else perform full validation with all of those
		// fixed-line/mobile/etc regular expressions.
		else if (getNumberType({ phone: nationalPhoneNumber, country }, undefined, metadata.metadata)) {
			return country
		}
	}
}

/**
 * @param  {string} text - Input.
 * @return {object} `{ ?number, ?ext }`.
 */
function parseInput(text, v2) {
	// Parse RFC 3966 phone number URI.
	if (text && text.indexOf('tel:') === 0) {
		return parseRFC3966(text)
	}
	let number = extractFormattedPhoneNumber(text, v2)
	// If the phone number is not viable, then abort.
	if (!number || !isViablePhoneNumber(number)) {
		return {}
	}
	// Attempt to parse extension first, since it doesn't require region-specific
	// data and we want to have the non-normalised number here.
	const with_extension_stripped = extractExtension(number)
	if (with_extension_stripped.ext) {
		return with_extension_stripped
	}
	return { number }
}

/**
 * Creates `parse()` result object.
 */
function result(country, national_number, ext) {
	const result = {
		country,
		phone : national_number
	}
	if (ext) {
		result.ext = ext
	}
	return result
}

/**
 * Parses a viable phone number.
 * @param {string} formattedPhoneNumber
 * @param {string} [defaultCountry]
 * @param {string} [defaultCallingCode]
 * @param {Metadata} metadata
 * @return {object} Returns `{ country: string?, countryCallingCode: string?, nationalNumber: string? }`.
 */
function parsePhoneNumber(
	formattedPhoneNumber,
	defaultCountry,
	defaultCallingCode,
	metadata
) {
	let { countryCallingCode, number } = extractCountryCallingCode(
		formattedPhoneNumber,
		defaultCountry,
		metadata.metadata
	)

	let country

	if (countryCallingCode) {
		metadata.chooseCountryByCountryCallingCode(countryCallingCode)
	}
	// If `formattedPhoneNumber` is in "national" format
	// then `number` is defined and `countryCallingCode` isn't.
	else if (number && (defaultCountry || defaultCallingCode)) {
		metadata.selectNumberingPlan(defaultCountry, defaultCallingCode)
		country = defaultCountry || (metadata.isNonGeographicCallingCode(defaultCallingCode) ? '001' : undefined)
		countryCallingCode = defaultCallingCode || getCountryCallingCode(defaultCountry, metadata.metadata)
	}
	else return {}

	if (!number) {
		return { countryCallingCode }
	}

	const { nationalNumber, carrierCode } = parseNationalNumber(number, metadata)

	// Sometimes there are several countries
	// corresponding to the same country phone code
	// (e.g. NANPA countries all having `1` country phone code).
	// Therefore, to reliably determine the exact country,
	// national (significant) number should have been parsed first.
	//
	// When `metadata.json` is generated, all "ambiguous" country phone codes
	// get their countries populated with the full set of
	// "phone number type" regular expressions.
	//
	const exactCountry = findCountryCode(countryCallingCode, nationalNumber, metadata)
	if (exactCountry) {
		country = exactCountry
		if (country !== '001') {
			metadata.country(country)
		}
	}

	return {
		country,
		countryCallingCode,
		nationalNumber,
		carrierCode
	}
}

function parseNationalNumber(number, metadata) {
	let nationalNumber = parseIncompletePhoneNumber(number)
	let carrierCode

	// Parsing national prefixes and carrier codes
	// is only required for local phone numbers
	// but some people don't understand that
	// and sometimes write international phone numbers
	// with national prefixes (or maybe even carrier codes).
	// http://ucken.blogspot.ru/2016/03/trunk-prefixes-in-skype4b.html
	// Google's original library forgives such mistakes
	// and so does this library, because it has been requested:
	// https://github.com/catamphetamine/libphonenumber-js/issues/127
	const {
		number: potentialNationalNumber,
		carrierCode: potentialCarrierCode
	} = stripNationalPrefixAndCarrierCode(nationalNumber, metadata)

	// If metadata has "possible lengths" then employ the new algorythm.
	if (metadata.possibleLengths()) {
		// We require that the NSN remaining after stripping the national prefix and
		// carrier code be long enough to be a possible length for the region.
		// Otherwise, we don't do the stripping, since the original number could be
		// a valid short number.
		switch (checkNumberLengthForType(potentialNationalNumber, undefined, metadata)) {
			case 'TOO_SHORT':
			// case 'IS_POSSIBLE_LOCAL_ONLY':
			case 'INVALID_LENGTH':
				break
			default:
				nationalNumber = potentialNationalNumber
				carrierCode = potentialCarrierCode
		}
	} else {
		// If the original number (before stripping national prefix) was viable,
		// and the resultant number is not, then prefer the original phone number.
		// This is because for some countries (e.g. Russia) the same digit could be both
		// a national prefix and a leading digit of a valid national phone number,
		// like `8` is the national prefix for Russia and both
		// `8 800 555 35 35` and `800 555 35 35` are valid numbers.
		if (matchesEntirely(nationalNumber, metadata.nationalNumberPattern()) &&
				!matchesEntirely(potentialNationalNumber, metadata.nationalNumberPattern())) {
			// Keep the number without stripping national prefix.
		} else {
			nationalNumber = potentialNationalNumber
			carrierCode = potentialCarrierCode
		}
	}

	return {
		nationalNumber,
		carrierCode
	}
}

// Parses a formatted phone number
// and returns `{ countryCallingCode, number }`
// where `number` is just the "number" part
// which is left after extracting `countryCallingCode`
// and is not necessarily a "national (significant) number"
// and might as well contain national prefix.
//
export function extractCountryCallingCode(number, country, metadata) {
	number = parseIncompletePhoneNumber(number)

	if (!number) {
		return {}
	}

	// If this is not an international phone number,
	// then don't extract country phone code.
	if (number[0] !== '+') {
		// Convert an "out-of-country" dialing phone number
		// to a proper international phone number.
		const numberWithoutIDD = stripIDDPrefix(number, country, metadata)

		// If an IDD prefix was stripped then
		// convert the number to international one
		// for subsequent parsing.
		if (numberWithoutIDD && numberWithoutIDD !== number) {
			number = '+' + numberWithoutIDD
		} else {
			return { number }
		}
	}

	// Fast abortion: country codes do not begin with a '0'
	if (number[1] === '0') {
		return {}
	}

	metadata = new Metadata(metadata)

	// The thing with country phone codes
	// is that they are orthogonal to each other
	// i.e. there's no such country phone code A
	// for which country phone code B exists
	// where B starts with A.
	// Therefore, while scanning digits,
	// if a valid country code is found,
	// that means that it is the country code.
	//
	let i = 2
	while (i - 1 <= MAX_LENGTH_COUNTRY_CODE && i <= number.length) {
		const countryCallingCode = number.slice(1, i)
		if (metadata.hasCallingCode(countryCallingCode)) {
			return {
				countryCallingCode,
				number: number.slice(i)
			}
		}
		i++
	}

	return {}
}
